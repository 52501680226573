// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_kC";
export var caseStudyBackground__bgColor = "bk_ky";
export var caseStudyBackground__lineColor = "bk_kz";
export var caseStudyHead = "bk_kt";
export var caseStudyHead__imageAbsolute = "bk_kw";
export var caseStudyHead__imageWrapper = "bk_kv";
export var caseStudyProjectsSection = "bk_kD";
export var caseStudyQuote__bgRing = "bk_kx";
export var caseStudyTechSection = "bk_kB";
export var caseStudy__bgDark = "bk_ks";
export var caseStudy__bgLight = "bk_kr";